import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  readonly APIUrl = "https://api.gshouston.org";
  readonly PhotoUrl = "https://api.gshouston.org/media";
  // readonly APIUrl = "http://127.0.0.1:8000";
  // readonly PhotoUrl = "http://127.0.0.1:8000/media";
  // readonly APIUrl = "http://172.31.199.45:8000";
  // readonly PhotoUrl = "http://172.31.199.45:8000/media";

  constructor(private http: HttpClient) { }
  // For Family Apis

  getFamilyList(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/family/');
  }
  getFamilyListPerID(id: number): Observable<Object> {
    // return this.http.get(`${this.APIUrl}'/family/'${id}`); // not working search for this type of data passing
    return this.http.get(this.APIUrl + '/family/' + id);
  }

  // to get members list according to family id
  membersfamilylist(id: number): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/membersfamilylist/' + id);
  }

  boardemailtempadmin(member: any): Observable<Object> {
    return this.http.get(this.APIUrl + '/boardemailtempadmin/' + member);
  }

  deleteMemberFamily(id: number, value: any): Observable<Object> {
    return this.http.put(this.APIUrl + '/deletememberfamily/' + id, value);
  }

  addFamily(family: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/family/', family);
  }
  deleteFamily(id: number): Observable<any> {
    // return this.http.delete(`${this.APIUrl}/${id}`);
    return this.http.delete(this.APIUrl + '/family/' + id);
  }

  updateFamily(id: number, value: any): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
    return this.http.put(this.APIUrl + '/family/' + id, value, httpOptions);
  }

  // For Members Api
  getMembersList(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/members');
  }

  stateList(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/stateList');
  }

  listnewMember(member: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/listMember', member);
  }

  getMembersListIndividual(id: any): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/membersIndiApi/' + id);
  }

  addMember(member: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/members', member);
  }

  updateMember(id: number, value: any): Observable<Object> {
    return this.http.put(this.APIUrl + '/memberupdate/' + id, value);
  }

  updateMemberLimited(id: number, value: any): Observable<Object> {
    return this.http.put(this.APIUrl + '/memberUpdateLimited/' + id, value);
  }

  updatePaymentLimited(id: number, value: any): Observable<Object> {
    return this.http.put(this.APIUrl + '/memberPaymentLimited/' + id, value);
  }

  renewupdateMember(id: number, value: any): Observable<Object> {
    return this.http.put(this.APIUrl + '/nonmember/memberrenew/' + id, value);
  }

  // For Membership Plan Page
  getMemberShipPlanList(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/memberplan');
  }

  updateMemberShipPlan(id: number, value: any): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
    return this.http.put(this.APIUrl + '/memberplan/' + id, value, httpOptions);
  }

  addMemberShipBoard(member: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/memberBoard', member);
  }

  listMemberShipBoard(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/memberBoard');
  }

  updateMemberShipBoard(id: number, value: any): Observable<Object> {
    return this.http.put(this.APIUrl + '/memberBoard/' + id, value);
  }

  deleteMemberShipBoard(id: number): Observable<any> {
    // return this.http.delete(`${this.APIUrl}/${id}`);
    return this.http.delete(this.APIUrl + '/memberBoard/' + id);
  }

  deleteMember(id: number): Observable<any> {
    // return this.http.delete(`${this.APIUrl}/${id}`);
    return this.http.delete(this.APIUrl + '/memberupdate/' + id);
  }

  // For all event photo add / view 

  getEventPhoto(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/event');
  }

  getEventPhotcurrentUpcomingView(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/currentupcomingevent');
  }
  // For current event photo view 

  getcurrentEventPhoto(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/currentevent');
  }
  // For upcoming event photo view 

  getUpcomingEventPhoto(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/upcomingevent');
  }
  // For past event photo  view 

  getPastEventPhoto(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/pastevent');
  }

  addEventPhoto(photo: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/event', photo);
  }
  // update event 
  updateEvent(id: number, value: any): Observable<Object> {
    return this.http.put(this.APIUrl + '/eventupdatedelete/' + id, value);
  }
  // delete event
  deleteEvent(id: number): Observable<any> {
    // return this.http.delete(`${this.APIUrl}/${id}`);
    return this.http.delete(this.APIUrl + '/eventupdatedelete/' + id);
  }

  // to get members list according to family id
  vieweventlist(id: number): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/event/' + id);
  }

  paymentdata(user: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/nonmember/paydata', user);
  }

  //login User 

  login(user: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/user/signin', user);
  }

  // login member api
  memlogin(user: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/memlogin', user);
  }

  // accesstoken for super admin
  accToken(id: string): Observable<Object> {
    // return this.http.get(`${this.APIUrl}'/family/'${id}`); // not working search for this type of data passing
    return this.http.get(this.APIUrl + '/user/accToken/' + id);
  }

  // accesstoken for gsh admin
  accTokengsh(id: string): Observable<Object> {
    // return this.http.get(`${this.APIUrl}'/family/'${id}`); // not working search for this type of data passing
    return this.http.get(this.APIUrl + '/user/accTokengsh/' + id);
  }

  // accesstoken for member
  accTokenmem(id: string): Observable<Object> {
    // return this.http.get(`${this.APIUrl}'/family/'${id}`); // not working search for this type of data passing
    return this.http.get(this.APIUrl + '/user/accTokenmem/' + id);
  }
  // verify address
  zipcode(value: any): Observable<Object> {
    return this.http.post(this.APIUrl + '/ajax/validate_zipcode/', value);
  }


  // register for Gsh Admin

  gshAdminRegister(member: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/user/gshsignup', member);
  }
  // update values for GSH Admin
  gshAdminUpdate(id: number, value: any): Observable<Object> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json',
    //     Authorization: 'my-auth-token'
    //   })
    // };
    return this.http.put(this.APIUrl + '/user/gshsignup/' + id, value);
  }
  // password update for Gsh Admin
  passupdate(id: number, value: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/user/passupdate/' + id, value);
  }
  // password update for Gsh Member
  passupdatemember(id: number, value: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/user/passupdatemem/' + id, value);
  }

  // password reset for gsh admin
  resetupdate(id: String, value: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/user/gshresetpass/' + id, value);
  }

  // password reset for gsh members
  resetmemberupdate(id: String, value: Object): Observable<Object> {
    return this.http.post(this.APIUrl + '/user/memresetpass/' + id, value);
  }

  gshresetpass(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/user/gshresetpass', value);
  }
  // member forget password screen call this api

  memberresetpass(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/user/memresetpass', value);
  }

  // for member chart 
  memchart(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/memchart');
  }

  // for all chart 
  allchart(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/allchart');
  }


  logs(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/logs/', value);
  }

  logslist(): Observable<any> {
    return this.http.get(this.APIUrl + '/logs/');
  }
  // for member mail from members list 
  memmail(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/memmail', value);
  }

  // for member mail check list 
  membercheckemail(id: String): Observable<any> {
    return this.http.get(this.APIUrl + '/membercheckemail/' + id);
  }

  // for member payment 
  payment(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/payment', value);
  }

  // for member dependent payment 
  paymentdependent(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/payment_dependent', value);
  }

  // for member payment 
  paymentget(id: String): Observable<any> {
    return this.http.get(this.APIUrl + '/payment/' + id);
  }
  // for renew member payment 
  renewpaymentget(id: String): Observable<any> {
    return this.http.get(this.APIUrl + '/nonmember/memberrenew/' + id);
  }

  // for non member mail check list 
  nonmemberget(id: String): Observable<any> {
    return this.http.get(this.APIUrl + '/nonmember/' + id);
  }

  // for non member payment 
  nonmember(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/nonmember/iview', value);
  }

  // for non member payment 
  paymentnonmember(id: String): Observable<any> {
    return this.http.get(this.APIUrl + '/nonmember/paymentnonmember/' + id);
  }

  // for non member payment 
  paymenttrasactionnonmember(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/nonmember/paymentnonmember', value);
  }
  // for trasactiontable payment 
  trasactiontable(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/nonmember/trasactiontable', value);
  }

  // for member ticket payment 
  paymenttrasactionmember(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/nonmember/memberticket', value);
  }

  // payment ticket information for member for gsh admin
  // return this.http.get(`${this.APIUrl}'/family/'${id}`); // not working search for this type of data passing
  // paymentticketinformation(): Observable<Object> {
  //   return this.http.get(this.APIUrl + '/nonmember/paymentmemberinformation');
  // }

  paymentticketinformation(pageIndex: number, pageSize: number): Observable<Object> {
    const params = {
      page: (pageIndex + 1).toString(),
      page_size: pageSize.toString(),
    };
    return this.http.get<any>(`${this.APIUrl}/nonmember/paymentmemberinformation`, { params });
  }

  // payment information for member for gsh admin
  paymentallmem(): Observable<Object> {
    // return this.http.get(`${this.APIUrl}'/family/'${id}`); // not working search for this type of data passing
    return this.http.get(this.APIUrl + '/nonmember/paymentallmem');
  }

  // for  member maxticket number of ticket to purchase 
  maxticket(id: String, memberid: any): Observable<any> {
    return this.http.get(this.APIUrl + '/nonmember/maxticket/' + id + "/" + memberid);
  }

  // for  member maxticket number of ticket to purchase 
  maxticketDate(id: String, memberid: any, date: String): Observable<any> {
    return this.http.get(this.APIUrl + '/nonmember/maxticket/' + id + "/" + memberid + "/" + date);
  }



  // for member ticketreport payment 
  ticketreport(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/ticketreport', value);
  }

  // for member memberreport payment 
  memberreport(value: Object): Observable<any> {
    return this.http.post(this.APIUrl + '/memberreport', value);
  }

  memberreportdownload(): Observable<any> {
    return this.http.get(this.APIUrl + '/memberreportdownload', { observe: 'response', responseType: 'blob' as 'json' });
  }

}
