import { Component,ChangeDetectorRef, OnInit } from '@angular/core';
import {SharedService} from "./../../shared.service"
import { NotificationService } from './../../notification.service'
import * as $ from 'jquery'
import { FormBuilder, Validators } from '@angular/forms';    
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-membership-board',
  templateUrl: './membership-board.component.html',
  styleUrls: ['./membership-board.component.scss']
})
export class MembershipBoardComponent implements OnInit {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor(private service:SharedService , private formbulider: FormBuilder, private route:Router ,private ref: ChangeDetectorRef, private notifyService : NotificationService) { }
  memberplanList : any[];
  memberplanAllList : any[];
  searchText;
  APIUrl;
  imageSrc: string= "";
  selectedFile:File;
  UserForm: any;    
  familyList:any=[]; 
  updateMemberBoard =  false;
  validstart;
  validend;
  dataid;
  public isValidFlg:boolean = true;

  ngOnInit(): void {
    this.APIUrl = this.service.APIUrl;
    this.refresh();
    this.UserForm = this.formbulider.group({    
      fName: ['',Validators.required],    
      lName: [''],    
      email: ['', [Validators.required , Validators.email]],    
      contactPhone: ['',Validators.required],   
      memberID: [''],   
      image: [''],   
      memberBoardType: [''],   
      validityFrom: [''],   
      validityTo: [''],   
    });
    setInterval(() => { this.ref.detectChanges(); }, 0);

  }
  refresh(){
    this.service.listMemberShipBoard().subscribe(data => {
      this.memberplanList = data;
      this.memberplanAllList = data;
      this.memberplanList = this.memberplanList.filter(item => item.memberBoardType === "Excecutive Member");    

    })
  }

  searchmembertype(value){
    if (value == "All"){
      this.memberplanList = this.memberplanAllList;
    }else{
      this.memberplanList = this.memberplanAllList.filter(item => item.memberBoardType === value);    
    }
  }

  editMemberBoard(memberid) {
    this.updateMemberBoard =  true;
    this.dataid= memberid;
    // console.log(this.memberplanAllList)
    var newvalue = this.memberplanAllList.filter(item => parseInt(item.id) === parseInt(memberid));  
    if (newvalue[0]['memberBoardType'] == 'Temp Admin'){
      this.service.boardemailtempadmin(newvalue[0]['email']).subscribe(data => {
        this.UserForm.patchValue({
          fName: newvalue[0]['fName'] || "",
          lName: newvalue[0]['lName'] || "",
          email: newvalue[0]['email'] || "",
          memberBoardType: newvalue[0]['memberBoardType'] || "",
          contactPhone: newvalue[0]['contactPhone'] || "",
          image: "",
          memberID: "",
          validityFrom: data[0]['validityFrom'] || "",
          validityTo: data[0]['validityTo'] || "",
       });   
      })
      
   
    }else {
    // console.log("newvalue",newvalue[0]['fName'])
    // console.log("memberboardType",newvalue[0]['memberBoardType'])
    this.UserForm.patchValue({
      fName: newvalue[0]['fName'] || "",
      lName: newvalue[0]['lName'] || "",
      email: newvalue[0]['email'] || "",
      memberBoardType: newvalue[0]['memberBoardType'] || "",
      contactPhone: newvalue[0]['memberID']['contactPhone'] || "",
      image: newvalue[0]['memberID']['memberImage'] || "",
      memberID: newvalue[0]['memberID']['MemberId'] || "",
      validityFrom: newvalue[0]['memberID']['validityFrom'] || "",
      validityTo: newvalue[0]['memberID']['validityTo'] || "",
   }); 
  }
  }
  deletePic() {
    // this.formGroup.value.pic = '';
    this.imageSrc = '';
    this.selectedFile = null;
  }

  onFileChange(event) {
    const reader = new FileReader();
    this.selectedFile = event.target.files[0]

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
           this.imageSrc = reader.result as string;    
   };
   }
  }

  validatePhoneNo(field) {
    var phoneNumDigits = field.value.replace(/\D/g, '');
  
    this.isValidFlg = (phoneNumDigits.length >= 10);
  
    var formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length >= 10){
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6,10);
    }
      else if (phoneNumDigits.length >= 6){
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
    }else if (phoneNumDigits.length >= 3){
        formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
    }
  
    field.value = formattedNumber;
  } 
  onFormSubmit()  {    
    const authDataGA = JSON.parse(
      localStorage.getItem(this.authLocalStorageToken+"ga")
    );

    const user = this.UserForm.value; 
    if (this.UserForm.value.memberID){
      user.memberID = parseInt(this.UserForm.value.memberID) 
    }else{
      user.memberID = ''
    }    // console.log("user",user)
    const uploadData1 = new FormData();
      if (this.selectedFile){ 
        uploadData1.append('image', this.selectedFile); }
      if (this.validstart) { 
        uploadData1.append('validityFrom', this.validstart); }
      else { uploadData1.append('validityFrom', this.UserForm.value.validityFrom); }
      if (this.validend) { 
        uploadData1.append('validityTo', this.validend); }
      else { uploadData1.append('validityTo', this.UserForm.value.validityTo); }
      if(this.UserForm.value.memberBoardType == "Temp Admin"){
        uploadData1.append('is_staff', "True"); }
        else { uploadData1.append('is_staff', "False"); }
      uploadData1.append('fName', this.UserForm.value.fName);
      uploadData1.append('lName', this.UserForm.value.lName);
      uploadData1.append('email', this.UserForm.value.email);
      uploadData1.append('contactPhone', this.UserForm.value.contactPhone);
      uploadData1.append('memberID', user.memberID);
      uploadData1.append('memberBoardType', this.UserForm.value.memberBoardType);
      uploadData1.append('access_token', authDataGA || "");
      this.service.updateMemberShipBoard(this.dataid,uploadData1)
      .subscribe(
        data => {
            // console.log(data);
            this.refresh();
            this.notifyService.showSuccess("Updated Successfully", "Updated")
              },
        error => {
          console.log("error",error.error)
          this.notifyService.showError("Error in Updating", "Error")
        });  
        
  }    

  onChangeEvent6(data){
    this.validstart = new Date(data.value).toISOString().split("T")[0]
  }
  onChangeEvent(data){
    this.validend = new Date(data.value).toISOString().split("T")[0]
  }

  cancel(){
    this.updateMemberBoard =  false;
    this.route.navigate(['/listMemberBoard']); // navigate to other page
  }


  deleteFamily(data) {
    // console.log("delete",data)
    this.service.deleteMemberShipBoard(data).subscribe(
        data => {
          // console.log(data);
          this.refresh();
          this.ref.detach();
          setInterval(() => { this.ref.detectChanges(); }, 1000);
          this.notifyService.showWarning("Deleted Record successfully !!", "Delete")
        },
        error => console.log(error));
  }
}
